import React from "react"
import Layout from "../components/layout";
import { Helmet } from "react-helmet"; 
import Swiper from "../components/swiper"; 
import Components from "../components/components.js";
import { Fade } from "react-awesome-reveal";

const  component = {
    name: 'Testimonial'
  }
 
const Home = () => (
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title> Spark Performance</title> 
      <meta name="google-site-verification" content="uAmurh3mCulprhzg-dlu__36ISjnvRfgs30cOtadRao" />
    </Helmet>
    <Layout>
	
	<Swiper />
	
<section className="wrap site-section " data-level="0"  data-wid="49"  >
            <div className="container">
  				<div className="col-12  wrapcontent" data-pid="50"  > 
				</div></div>
				</section>
        
        
        <section className="wrap site-section bg-red-700 bg-gradient-to-t from-red-900 to-red-700" data-level="0"  data-wid="35"  >
            <div className="wrap site-section pt-12 sm:pt-16 lg:pt-20" data-level="1"  data-wid="34"  >
            <div className="wrap site-section max-w-7xl mx-auto px-4 sm:px-6 lg:px-8" data-level="2"  data-wid="33"  >
            <div className=" text-center pbox wrapcontent" data-pid="40"  ><div className="  wrapcontent" data-pid="41"  > <div className="  wrapcontent" data-pid="54"  >
					
				<Fade cascade>
					<div className="l-box">
						<p className="  text-3xl text-white">Mé jméno je <strong>Jan Jiskra</strong> a jsem silově kondiční trenér.</p>
				</div>
				</Fade>
				</div>
				</div>
				</div></div></div><div className="wrap site-section mt-8 pb-16 sm:mt-12 sm:pb-20 lg:pb-28" data-level="2"  data-wid="37"  >
            <div className="wrap site-section relative" data-level="3"  data-wid="36"  >
            <div className="wrap site-section relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8  " data-level="4"  data-wid="39"  >
            
			<Fade cascade>
			<div className="wrap site-section max-w-lg mx-auto rounded-lg shadow-lg overflow-hidden lg:max-w-none lg:flex" data-level="5"  data-wid="38"  >
            
			<div className=" flex-1 bg-white px-6 py-8 lg:p-12 pbox wrapcontent" data-pid="44"  ><div className="  wrapcontent" data-pid="45"  >
          <p className="mt-6 text-base text-gray-500 ">Proč Spark Performance? Spark je v překladu jiskra a performance výkon. Mým cílem je posouvat výkony svých svěřenců na vyšší level. Pracuji jak s profesionálními sportovci, mládeží, tak běžnou populací. Zaměřuji se na silově kondiční trénink jednotlivců a týmů. Dále na pohybovou nápravu, prevencí zranění a rekonvalescenci po zranění.</p>
          	
          
				</div><div className=" mt-8 wrapcontent" data-pid="46"  ><div className="flex items-center">
<h4 className="flex-shrink-0 pr-4 bg-white text-sm tracking-wider font-semibold uppercase text-red-600">Jaké služby nabízím?</h4>
<div className="flex-1 border-t-2 border-gray-200"></div>
</div>
<ul className="mt-8 space-y-5 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5">
<li className=" lg:col-span-1">
<div className="flex items-start">
<div className="flex-shrink-0"><svg version="1.1" id="Layer_1" className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 150.15 150.147" enableBackground="new 0 0 150.15 150.147"><path fillRule="evenodd" clipRule="evenodd" fill="#E3000F" d="M66.653,124.551c-25.36-3.604-44.862-25.401-44.862-51.757
                        c0-27.744,21.611-50.437,48.917-52.173l-5.645,11.812c-18.502,4.113-32.337,20.62-32.337,40.361
                        c0,20.984,15.632,38.315,35.886,40.989L66.653,124.551z M77.373,114.007c21.293-1.682,38.051-19.486,38.051-41.212
                        c0-22.633-18.186-41.016-40.742-41.341l-6.639,32.143l30.17,0.284L77.373,114.007z M76.926,20.593
                        c27.549,1.48,49.434,24.283,49.434,52.201c0,28.876-23.409,52.284-52.284,52.284c-0.434,0-0.867-0.006-1.298-0.017l-10.429,25.086
                        l17.236-71.114L46.219,78.72L80.766,2L76.926,20.593z"></path></svg></div>
<p className="ml-3 text-lg text-gray-700 w-full">Silově kondiční trénink pro sportovce</p>
</div>
<p className="block text-gray-500 mt-2 ml-8">U profesionálů chci zlepšit jejich výkon ve sportu a zároveň je udržet zdravé. Vždycky analyzuji slabá místa, na kterých je potřeba pracovat, aby mohli podávat lepší výsledky v daném sportu.</p>
</li>
<li className=" lg:col-span-1">
<div className="flex items-start">
<div className="flex-shrink-0"><svg version="1.1" id="Layer_1" className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 150.15 150.147" enableBackground="new 0 0 150.15 150.147"><path fillRule="evenodd" clipRule="evenodd" fill="#E3000F" d="M66.653,124.551c-25.36-3.604-44.862-25.401-44.862-51.757
                        c0-27.744,21.611-50.437,48.917-52.173l-5.645,11.812c-18.502,4.113-32.337,20.62-32.337,40.361
                        c0,20.984,15.632,38.315,35.886,40.989L66.653,124.551z M77.373,114.007c21.293-1.682,38.051-19.486,38.051-41.212
                        c0-22.633-18.186-41.016-40.742-41.341l-6.639,32.143l30.17,0.284L77.373,114.007z M76.926,20.593
                        c27.549,1.48,49.434,24.283,49.434,52.201c0,28.876-23.409,52.284-52.284,52.284c-0.434,0-0.867-0.006-1.298-0.017l-10.429,25.086
                        l17.236-71.114L46.219,78.72L80.766,2L76.926,20.593z"></path></svg></div>
<p className="ml-3 text-lg text-gray-700 w-full">Silově kondiční trénink pro běžnou populaci</p>
</div>
<p className="block text-gray-500 mt-2 ml-8">Vždycky se snažím použít ty nejlepší osvědčené metody, abychom dosáhli požadovaných cílů. Ať už chce klient zhubnout, nabrat svalovou hmotu nebo celkově zlepšit své zdraví.</p>
</li>
</ul>
				</div>
				</div>
				
				
				<div className=" py-4 px-3 text-center bg-gray-50 lg:flex-shrink-0 lg:flex lg:flex-col lg:justify-center lg:p-6 lg:max-w-sm pbox wrapcontent" data-pid="47"  ><div className="  wrapcontent" data-pid="48"  ><img src="https://www.sparkperformance.cz/admin/data/2021/03/22/profil-605878ec3ea37.jpg" className="pure-img" alt="" />
				</div>
				</div></div>
				
				</Fade>
				</div></div></div></section>
        <Fade >
        <section className="wrap site-section " data-level="0"  data-wid="51"  >
            <div className="container">
  				<div className="col-12  wrapcontent" data-pid="52"  > {Components(component)}
				</div></div>
				</section>
				</Fade>
    </Layout>
  </>
)

export default Home
 